var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"400px","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',[_c('ValidationObserver',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"d-flex flex-column flex-grow-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addNewPrice)}}},[_c('v-chip-group',{staticClass:"ml-3",attrs:{"mandatory":"","active-class":"primary"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((['Choose From Price', 'Add New Price']),function(tab,index){return _c('v-chip',{key:index,staticClass:"box-shadow-light",attrs:{"color":"white","label":"","small":""}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(_vm._s(tab))])])}),1),(_vm.tabIndex === 0)?_c('v-card-text',[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"font font-weight-medium font-size-sm",attrs:{"items":_vm.availablePrices,"item-text":"amount","item-value":"id","return-object":"","prepend-icon":"payment","prefix":_vm.currency && _vm.currency.length > 0 ? _vm.currency[0].symbol : 'GHS',"autofocus":"","label":"Choose From Prices To Set As Current Selling Price","error-messages":errors[0],"hint":"Set Price As Current Selling Price"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for already available "),_c('kbd',[_vm._v("prices")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font font-weight-medium font-size-md py-2"},[_vm._v(" "+_vm._s(item.amount))])]}}],null,true),model:{value:(_vm.priceId),callback:function ($$v) {_vm.priceId=$$v},expression:"priceId"}})]}}],null,true)})],1):_c('v-card-text',[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"autofocus":"","prefix":_vm.currency && _vm.currency.length > 0 ? _vm.currency[0].symbol : 'GHS',"error-messages":errors[0],"label":"New Fuel Price","prepend-icon":"credit_card","hint":"Enter product price eg. 6.70"},model:{value:(_vm.product.amount),callback:function ($$v) {_vm.$set(_vm.product, "amount", $$v)},expression:"product.amount"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_c('span',{staticClass:"font text-capitalize font-weight-medium font-size-md"},[_vm._v(" Cancel ")])]),_c('v-btn',{attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading,"color":"primary"}},[_c('span',{staticClass:"font text-capitalize font-weight-medium font-size-md"},[_vm._v(" Save ")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }