<template>
  <v-dialog v-model="state" width="400px" persistent>
    <v-card>
      <ValidationObserver
        tag="div"
        class="d-flex flex-row flex-grow-1"
        v-slot="{ handleSubmit }"
      >
        <form
          class="d-flex flex-column flex-grow-1"
          @submit.prevent="handleSubmit(addNewPrice)"
        >
          <v-chip-group
            mandatory
            class="ml-3"
            active-class="primary"
            v-model="tabIndex"
          >
            <v-chip
              v-for="(tab, index) in ['Choose From Price', 'Add New Price']"
              color="white"
              class="box-shadow-light"
              label
              small
              :key="index"
            >
              <span class="font font-weight-medium font-size-sm">{{
                tab
              }}</span>
            </v-chip>
          </v-chip-group>
          <v-card-text v-if="tabIndex === 0">
            <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
              <v-autocomplete
                :items="availablePrices"
                item-text="amount"
                item-value="id"
                v-model="priceId"
                return-object
                prepend-icon="payment"
                :prefix="
                  currency && currency.length > 0 ? currency[0].symbol : 'GHS'
                "
                autofocus
                label="Choose From Prices To Set As Current Selling Price"
                :error-messages="errors[0]"
                hint="Set Price As Current Selling Price"
                class="font font-weight-medium font-size-sm"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for already available
                      <kbd>prices</kbd>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <span class="font font-weight-medium font-size-md py-2">
                    {{ item.amount }}</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-card-text>
          <v-card-text v-else>
            <ValidationProvider
              tag="div"
              rules="required"
              v-slot="{ errors }"
              class="d-flex flex-column flex-grow-1"
            >
              <v-text-field
                autofocus
                :prefix="
                  currency && currency.length > 0 ? currency[0].symbol : 'GHS'
                "
                :error-messages="errors[0]"
                label="New Fuel Price"
                prepend-icon="credit_card"
                class="font font-weight-medium font-size-md"
                v-model="product.amount"
                hint="Enter product price eg. 6.70"
              />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="close" text>
              <span
                class="font text-capitalize font-weight-medium font-size-md"
              >
                Cancel
              </span>
            </v-btn>
            <v-btn
              type="submit"
              :loading="loading"
              :disabled="loading"
              color="primary"
            >
              <span
                class="font text-capitalize font-weight-medium font-size-md"
              >
                Save
              </span>
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Input field is required"
});
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "AddNewPrice",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: { type: Boolean, default: false },
    loading: {
      type: Boolean,
      default: false
    },
    currency: {
      type: Array,
      default: () => []
    },
    availablePrices: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState"
    })
  },
  data: () => ({
    product: {
      amount: 0,
      productId: null
    },
    tabIndex: 0,
    priceId: null
  }),
  watch: {
    resetFormState(value) {
      if (value) {
        this.tabIndex = 0;
        this.product = { amount: 0, productId: null };
        this.priceId = null;
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 2000);
    }
  },
  methods: {
    addNewPrice() {
      if (isNaN(parseFloat(this.product.amount))) {
        this.fireSnackBarMessage();
        return;
      }
      const details = this.$store.getters["products/getProductDetails"];
      const currentSelectedDate = this.$store.getters[
        "sales/getDataToRecordSales"
      ];
      let reqBody = {
        date:
          currentSelectedDate && currentSelectedDate.length > 0
            ? currentSelectedDate[0]?.dateAssigned
            : moment().format("YYYY-MM-DD"),
        body: {
          ...this.product,
          isCurrent: true,
          amount: parseFloat(this.product.amount),
          productId: details?.id
        }
      };
      if (this.priceId && this.tabIndex === 0) {
        reqBody.body.amount = this.priceId?.amount ?? 0;
        this.$emit("updateCurrentPrice", {
          id: this.priceId?.id,
          ...reqBody
        });
      } else {
        this.$emit("addNewPrice", { ...reqBody });
      }
    },
    close() {
      this.priceId = "";
      this.$emit("close", "price");
    },
    fireSnackBarMessage() {
      this.$store.commit("UPDATE_SNACKBAR", true, { root: true });
      this.$store.commit("UPDATE_MESSAGE", "Invalid value provided", {
        root: true
      });
      this.$store.commit("UPDATE_STATUS", "red", { root: true });

      setTimeout(() => {
        this.$store.commit("UPDATE_SNACKBAR", false, { root: true });
      }, 2000);
    }
  }
};
</script>
